<script setup>
const props = defineProps(["filter", "filtersStore"]);

const value = ref(props.filtersStore.selected_filters[props.filter.slug]?.value || []);
const setValue = (item) => {
  const isItemExistInValue = value.value.find(v => v.key == item.key);

  if (isItemExistInValue) {
    value.value = value.value.filter(v => v.key !== item.key);
  } else {
    value.value = [...value.value, item];
  }

  props.filtersStore.updateValue(props.filter, value.value);
}

const isSelected = (item) => {
  return value.value.find(v => v.key == item.key);
}

watch(() => props.filtersStore.selected_filters, () => {
  value.value = props.filtersStore.selected_filters[props.filter.slug] || [];

});
</script>

<template>
  <div class="border-b my-3 pb-3">
    <h3 class="text-lg font-medium text-indigo-900 leading-normal">{{ filter.name }}</h3>
    <div class="w-full flex gap-2 snap-x overflow-x-auto my-3">
      <div class="snap-start  shrink-0 relative" v-for="(item, idx) in filter.data_source">
        <template v-if="(filter.filter_value_more > 0 && filter.filter_value_more >= idx)">
          <button @click="setValue(item)" class="rounded-2xl p-3 block"
            :class="{ [isSelected(item) ? 'active-type' : 'bg-white']: true }">
            <h4 class="px-2">
              <span class="cursor-pointer">
                {{ item.label_value || item.value }}
                {{ filter.filter_value_more == idx ? '+' : '' }}
              </span>
            </h4>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>
